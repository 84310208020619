'use client';

import Link from 'next/link';
import { useState } from 'react';

import Modal from '@/common/components/Modal';
import ModalBody from '@/common/components/Modal/ModalBody';
import ModalHeader from '@/common/components/Modal/ModalHeader';
import RoundedButton from '@/common/components/buttons/RoundedButton';
import { useAccount } from '@/common/hooks/useAccount';

import CSS from './CandidateRedirect.module.scss';

const getNoRedirect = () => {
  try {
    return sessionStorage.getItem('hide-candidate-popup') === 'true';
  } catch (e) {
    return false;
  }
};

function CandidateRedirect() {
  const [hide, setHide] = useState(getNoRedirect());
  const { account } = useAccount();

  if (!account?.isCandidate) {
    return null;
  }

  const handleClose = () => {
    try {
      sessionStorage.setItem('hide-candidate-popup', 'true');
    } catch (e) {
      // ignore
    } finally {
      setHide(true);
    }
  };

  return (
    <Modal show={!hide} onClose={handleClose}>
      <ModalHeader className={CSS.Header}>
        <h1 className={CSS.Title}>
          Hello {account?.firstName} <span role="img">👋</span>
        </h1>
      </ModalHeader>
      <ModalBody className={CSS.Body}>
        <p className={CSS.Text}>
          You are almost ready to start hosting! Go to onboarding page to
          activate your account.
        </p>
        <p className={CSS.Text}>
          If you prefer to stay on this page for now, you can always return to
          onboarding by clicking the button in the upper right corner.
        </p>
        <RoundedButton as={Link} href="/host/onboarding/" className={CSS.CTA}>
          Continue onboarding
        </RoundedButton>
      </ModalBody>
    </Modal>
  );
}

export default CandidateRedirect;
